import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { getDatabase, ref, onValue, off } from 'firebase/database';

class BigScreenVideo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            videoState: 0,
            videoLink: "",
            timeToSendEmails: null,
            videoDuration: "0:00"
        };
    }

    componentDidMount() {
        const db = getDatabase();
        const videoStateRef = ref(db, 'currentGame/videoState');
        const videoLinkRef = ref(db, 'currentGame/videoLink');
        const timeToSendEmailsRef = ref(db, 'currentGame/timeToSendEmails');

        this.unsubscribeVideoState = onValue(videoStateRef, (snapshot) => {
            this.setState({ videoState: snapshot.val() || 0 });
        });

        this.unsubscribeVideoLink = onValue(videoLinkRef, (snapshot) => {
            this.setState({ videoLink: snapshot.val() || "" });
        });

        this.unsubscribeTimeToSendEmails = onValue(timeToSendEmailsRef, (snapshot) => {
            this.setState({ timeToSendEmails: snapshot.val() || null });
        });
    }

    componentWillUnmount() {
        if (this.unsubscribeVideoState) this.unsubscribeVideoState();
        if (this.unsubscribeVideoLink) this.unsubscribeVideoLink();
        if (this.unsubscribeTimeToSendEmails) this.unsubscribeTimeToSendEmails();
    }

    onEnded() {
        console.log("Video Ended");
    }

    onDuration(e) {
        this.setState({ videoDuration: e });
    }

    onProgress(e) {
        let seconds = 0;
        let { timeToSendEmails, videoDuration } = this.state;

        if (typeof timeToSendEmails === "object") {
            if (videoDuration > 5) {
                timeToSendEmails = videoDuration - 5;
            } else {
                timeToSendEmails = videoDuration;
            }
        }

        if (seconds === timeToSendEmails) {
            console.log("STATE FIRED");
            this.setState({ videoState: 2 });
        }

        if (seconds !== parseInt(e.playedSeconds)) {
            seconds = parseInt(e.playedSeconds);
        }
    }

    render() {
        const { videoState, videoLink } = this.state;
        return (
            <ReactPlayer
                url={videoLink}
                muted={true}
                playing={videoState === 1}
                onEnded={this.onEnded}
                onProgress={(e) => this.onProgress(e)}
                onDuration={(e) => this.onDuration(e)}
                controls={false}
                preload={"auto"}
                width="100%"
                height="100vh"
            />
        );
    }
}

export default BigScreenVideo;
