import Swal from 'sweetalert2';

const swalConfig = Swal.mixin({
    buttonsStyling: true,
});

export const showSuccessPopup = async (title, text, confirmButtonText) => {
    return swalConfig.fire({
        title: title,
        text: text,
        icon: 'success',
        confirmButtonText: confirmButtonText || 'Ok',
    });
};

export const showErrorPopup = async (title, text) => {
    return swalConfig.fire({
        title: title,
        text: text,
        icon: 'error',
    });
};

export const showInfoPopup = async (title, text) => {
    return swalConfig.fire({
        title: title,
        text: text,
        icon: 'info',
    });
};

export const showInputPopup = async (title, text, placeholder, validationFn = null) => {
    const result = await swalConfig.fire({
        title: title,
        text: text,
        input: 'text',
        inputPlaceholder: placeholder,
        showCancelButton: true,
        allowOutsideClick: false,
        inputValidator: (value) => {
            if (!value) {
                return 'This field cannot be empty!';
            }
            if (validationFn && typeof validationFn === 'function') {
                const validationError = validationFn(value);
                if (validationError) {
                    return validationError; // This will display the validation error message
                }
            }
            return null;
        }
    });

    if (result.isConfirmed) {
        return result.value;
    } else {
        return undefined; // Handles the case when the user cancels the input
    }
};

export const showToast = async (title, icon = 'success') => {
    return swalConfig.fire({
        title: title,
        icon: icon,
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
    });
};

export const showConfirmPopup = async (title, text, confirmButtonText = 'Yes', cancelButtonText = 'No') => {
    const result = await swalConfig.fire({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
        reverseButtons: true,
    });

    return result.isConfirmed;
};
