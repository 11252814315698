import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { getDatabase, ref, get, remove } from 'firebase/database'; // Firebase 10 functions
import '../../styles/css/AdminMain.css';
import { getURLParameter } from "../utils/HelpfulFunction";
import { showSuccessPopup, showErrorPopup, showConfirmPopup } from '../utils/SwalConfig';

class UserInfo extends Component {
    constructor(props) {
        super(props);
        const uid = getURLParameter("uid");
        this.state = {
            uid: uid,
            loading: true,
            advanced: false,
            userInfo: null,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        this.getUserInfo();
    }

    async getUserInfo() {
        try {
            const db = getDatabase(); // Initialize the database
            const userRef = ref(db, `users/${this.state.uid}`);
            const userInfoSnapshot = await get(userRef);
            const userInfo = userInfoSnapshot.val() || {};
            this.setState({
                loading: false,
                userInfo
            });
        } catch (error) {
            await showErrorPopup('Error', 'Failed to load user information.');
            this.setState({ loading: false });
        }
    }

    handleChange(evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({ [name]: value });
    }

    async deleteUser() {
        const confirmed = await showConfirmPopup(
            'Delete User?',
            'Are you sure you want to permanently delete this user? This action cannot be undone.',
            'Delete',
            'Cancel'
        );

        if (confirmed) {
            try {
                const db = getDatabase();
                const userRef = ref(db, `users/${this.state.uid}`);
                await remove(userRef);
                await showSuccessPopup('Deleted!', 'The user has been permanently deleted.', 'Ok');
            } catch (error) {
                await showErrorPopup('Error', 'Failed to delete the user.');
            }
        }
    }

    render() {
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <p className="admin-header-text" style={{ marginBottom: 0 }}>User Info</p>
                    <p className="admin-subheader-text">Here you can view your user</p>
                    <div className="container" style={{ color: "black", padding: 20 }}>
                        <div className="row" style={{ margin: 20 }}>
                            <div className="col" style={{ textAlign: "center" }}>
                                <p style={{ fontSize: 40 }}>User Info</p>
                            </div>
                        </div>
                        {/* Placeholder for user information */}
                        {/* Uncomment and modify as needed */}
                        {/* <div className="row">
                            <div className="col">
                                <p>Username: {userInfo.userName}</p>
                                <p>Email: {userInfo.email || "None Found"}</p>
                                <p>Signed Up: {userInfo.signUpTime ? convertTimeStampToHumanReadable(userInfo.signUpTime) : "None Detected"}</p>
                            </div>
                        </div> */}
                        <div className="row">
                            <div className="col">
                                <div className="form-check">
                                    <label className="form-check-label" htmlFor="advanced">Advanced</label>
                                    <input value={this.state.advanced} className="form-check-input" id="advanced" name="advanced" type="checkbox" checked={this.state.advanced} onChange={this.handleChange} />
                                </div>
                            </div>
                        </div>
                        {this.state.advanced &&
                            <div className="row">
                                <div className="col" style={{ textAlign: "center" }}>
                                    <button className="btn btn-danger" onClick={() => this.deleteUser()}>
                                        <span className="fa fa-trash pre_side_item" /> Permanently Delete User
                                    </button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default UserInfo;
