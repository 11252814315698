import { initializeApp } from 'firebase/app';
import { getDatabase } from 'firebase/database';
import {getAuth} from "firebase/auth";

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
  appId: process.env.REACT_APP_ID
};
// Initialize Firebase
const app = initializeApp(config);

const auth = getAuth(app);

// Initialize Database
const appDatabasePrimaryFunctions = getDatabase(app);

export { app, appDatabasePrimaryFunctions, auth };
