import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import {appDatabasePrimaryFunctions} from '../../base';
import '../../styles/css/AdminMain.css';
import { showSuccessPopup, showErrorPopup, showConfirmPopup } from '../utils/SwalConfig';
import {onValue, ref, remove, set} from "firebase/database";

const CryptoJS = require("crypto-js");

class SetUpWebHooks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usersCreatedLink: '',
            loading: true,
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const webHooksVariablesRef = ref(appDatabasePrimaryFunctions, 'webHooksVariables');

        onValue(webHooksVariablesRef, (snapshot) => {
            const data = snapshot.val();
            this.setState({
                webHooksVariables: data || {},
                loading: false,
            });
        }, (error) => {
            console.error(error);
            this.setState({ loading: false });
        });
    }

    handleChange(evt) {
        const webHooksVariablesCopy = { ...this.state.webHooksVariables };
        const target = evt.target;
        const name = target.name;
        webHooksVariablesCopy[name] = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ webHooksVariables: webHooksVariablesCopy });

        const webHooksVariablesRef = ref(appDatabasePrimaryFunctions, 'webHooksVariables');
        set(webHooksVariablesRef, webHooksVariablesCopy);
    }

    generateKey(size = 32, format = 'base64') {
        const buffer = CryptoJS.lib.WordArray.random(size);
        const webHooksVariablesCopy = { ...this.state.webHooksVariables };
        webHooksVariablesCopy.currentApiKey = buffer.toString(CryptoJS.enc.Hex);
        this.setState({ webHooksVariables: webHooksVariablesCopy });
        const webHooksVariablesRef = ref(appDatabasePrimaryFunctions, 'webHooksVariables');
        set(webHooksVariablesRef, webHooksVariablesCopy);
    }

    async deleteKey() {
        const confirmed = await showConfirmPopup(
            'Delete Key?',
            'Are you sure you want to delete the current API key? This action cannot be undone.',
            'Delete',
            'Cancel'
        );
        if (confirmed) {
            const webHooksVariablesCopy = { ...this.state.webHooksVariables };
            delete webHooksVariablesCopy.currentApiKey;

            this.setState({ webHooksVariables: webHooksVariablesCopy });

            const webHooksVariablesRef = ref(appDatabasePrimaryFunctions, 'webHooksVariables/currentApiKey');
            await remove(webHooksVariablesRef);

            await showSuccessPopup('Deleted!', 'The API key has been deleted.', 'Ok');
        }
    }

    handleSubmit(event) {
        event.preventDefault();
    }

    render() {
        const webHooksVariables = this.state.webHooksVariables || {};
        const isSqwadControlled = process.env.REACT_APP_SQWAD_CONTROL_KEYS === "true";
        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel" style={{ color: "black" }}>
                    <p className="admin-header-text" style={{ marginBottom: 0 }}>Web Hooks</p>
                    <p className="admin-subheader-text">Your auth key can be found here</p>
                    {webHooksVariables.currentApiKey &&
                        <div style={{ marginLeft: 20, color: "black" }}>
                            Current Key: <br />{webHooksVariables.currentApiKey}
                            {!isSqwadControlled &&
                                <>
                                    <button onClick={() => this.generateKey()} className="btn btn-primary btn-lg update-button" id="submitButton" style={{ marginLeft: 20 }}>
                                        <span className="fa fa-refresh" /> Generate New Key
                                    </button>
                                    <button onClick={() => this.deleteKey()} className="btn btn-primary btn-lg delete-button" id="submitButton" style={{ marginLeft: 20 }}>
                                        <span className="fa fa-trash" /> Delete
                                    </button>
                                </>
                            }
                        </div>
                    }
                    {!webHooksVariables.currentApiKey && !isSqwadControlled &&
                        <div>
                            <button onClick={() => this.generateKey()} className="btn btn-primary btn-lg update-button" id="submitButton" style={{ marginBottom: '20px', marginLeft: 20 }}>
                                <span className="fa fa-refresh" /> Generate Key
                            </button>
                        </div>
                    }
                    <p className="admin-subheader-text">Manage your web hooks here that will send messages to links you specify when certain actions are taken</p>
                    <ol>
                        <li>
                            <div className="mb-3">
                                <label htmlFor="userCreationWebHookLink" className="form-label">New Users Web Hook</label>
                                <div className="form-text">This web hook fires every time a new account is created, please enter a URL that you would like to have notified of user creation</div>
                                <input id="userCreationWebHookLink" name="userCreationWebHookLink" type="url" className="form-control" value={webHooksVariables.userCreationWebHookLink} onChange={this.handleChange} />
                            </div>
                        </li>
                        <li>
                            <div className="mb-3">
                                <label htmlFor="prizeWonWebHookLink" className="form-label">Prizes Won Web Hook</label>
                                <div className="form-text">This web hook fires every time a new prize is won, please enter a URL that you would like to have notified of the prize being won</div>
                                <input id="prizeWonWebHookLink" name="prizeWonWebHookLink" type="url" className="form-control" value={webHooksVariables.prizeWonWebHookLink} onChange={this.handleChange} />
                            </div>
                        </li>
                    </ol>
                    <p className="admin-header-text" style={{ marginBottom: 0 }}>Documentation</p>
                    <p className="admin-subheader-text">How to use Sqwad web hooks</p>
                    <a href="/webhooksdocumentation" style={{ marginLeft: 20 }}>See the docs</a>
                </div>
            </div>
        );
    }
}

export default SetUpWebHooks;
