import React, { Component } from 'react'
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import { Modal } from 'reactstrap';
import UploadImage from '../utils/UploadImage';
import '../../styles/css/AdminMain.css';
import { PhotoshopPicker } from 'react-color'
import {get, ref, set} from "firebase/database";
import {appDatabasePrimaryFunctions} from "../../base";
import {showErrorPopup, showSuccessPopup} from "../utils/SwalConfig";

class SetUpTeamVariables extends Component {
    constructor(props) {
        super(props);
        this.UploadImage = new UploadImage();
        this.state = {
            tenantVariables: {},
            showTab1Major: true,
            showTab2Major: false,
            showTab3Major: false,
            showTab1Minor: true,
            showTab2Minor: false,
            showTab3Minor: false,
            showTab1Scoreboard: true,
            showTab2Scoreboard: false,
            showTab3Scoreboard: false,
            showTab1gameTextToggle: true,
            loading: true,
            colorToShow: "#ffffff",
            colorToUpdate: "",
            colorPickerShow: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleImageChange = this.handleImageChange.bind(this);
    }

    componentDidMount() {
        const tenantVariablesRef = ref(appDatabasePrimaryFunctions, 'tenantVariables');
        get(tenantVariablesRef).then((snapshot) => {
            const data = snapshot.val();
            this.setState({
                tenantVariables: data || {},
                loading: false
            });
        }).catch((error) => {
            this.setState({ loading: false });
            showErrorPopup('Error loading data', error.message);
        });
    }

    handleSubmit(event) {
        event.preventDefault();
        this.setState({ loading: true });
        const variables = this.state.tenantVariables;
        set(ref(appDatabasePrimaryFunctions, 'tenantVariables'), variables)
            .then(() => {
                this.setState({ loading: false });
                showSuccessPopup('Tenant Variables Updated!', '');
            })
            .catch((err) => {
                this.setState({ loading: false });
                showErrorPopup('There was some error!', 'Try again and if the problem persists try logging out and logging back in');
                console.error(err);
            });
    }

    handleChange(evt) {
        const tenantVariablesCopy = { ...this.state.tenantVariables };
        tenantVariablesCopy[evt.target.name] = evt.target.value;
        this.setState({ tenantVariables: tenantVariablesCopy });
    }

    openColorPicker(colorToChange) {
        const oldColor = this.state.tenantVariables[colorToChange];
        this.setState({
            colorPickerShow: true,
            colorToShow: oldColor,
            colorToUpdate: colorToChange
        });
    }

    handleChangeComplete = (color) => {
        this.setState({
            colorToShow: color.hex
        });
    };

    acceptPicker() {
        const tenantVariablesCopy = { ...this.state.tenantVariables };
        tenantVariablesCopy[this.state.colorToUpdate] = this.state.colorToShow;
        this.setState({
            tenantVariables: tenantVariablesCopy,
            colorPickerShow: false,
        });
    }

    dismissPicker() {
        this.setState({
            colorPickerShow: false,
        });
    }

    handleImageChange(event) {
        const name_of_file = event.target.name;
        const target = this[name_of_file];
        const file_to_update = target.files[0];
        this.setState({ loading: true });
        this.UploadImage.upload_file(file_to_update).then((res) => {
            this.setState({ loading: false });
            if (res.error) {
                showErrorPopup('Image cannot be uploaded', res.error);
            } else if (res.imageUrl) {
                const tenantVariablesCopy = { ...this.state.tenantVariables };
                tenantVariablesCopy[name_of_file] = res.imageUrl;
                this.setState({
                    tenantVariables: tenantVariablesCopy,
                }, () => {
                    this[name_of_file].value = "";
                });
            } else {
                showErrorPopup('Image cannot be uploaded', 'There was some issue with the image upload, please check the image type and size and try again');
            }
        });
    }

    dynamicToggleControl(tabNumber, category, totalTabs) {
        let newState = {};

        // Loop through all tabs to reset their states and classes
        for (let i = 1; i <= totalTabs; i++) {
            const currentTabStateKey = `showTab${i}${category}`;
            const currentElementId = `show${i}${category}`;
            const tabElement = document.getElementById(currentElementId);

            if (i === tabNumber) {
                // This is the clicked tab
                if (tabElement) tabElement.classList.add('active');
                newState[currentTabStateKey] = true;
            } else {
                // Reset other tabs
                if (tabElement) tabElement.classList.remove('active');
                newState[currentTabStateKey] = false;
            }
        }
        this.setState(newState);
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};
        const isPrediction = process.env.REACT_APP_RACE_TYPE === "prediction";
        const leftOfScoreboardImage = tenantVariables.leftOfScoreboardImage || "";
        const topOfScoreboardImage = tenantVariables.topOfScoreboardImage || "";
        const leftScoreboardBackground = tenantVariables.leftScoreboardBackground || "";
        const topScoreboardBackground = tenantVariables.topScoreboardBackground || "";
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";
        return (
          <div className="admin-wrapper">
            <div className="loading-screen" style={{display: this.state.loading ? 'block' : 'none' }}/>
            <SideMenu/>
            <TopMenu/>
            <div className="admin-main-panel">
                <p className="admin-header-text" style={{marginBottom:0, marginTop:'5px'}}>Game Branding</p>
                <p className="admin-subheader-text">This is where you can edit the game branding on your activation</p>
                <div className="container-out">
                  <div className="admin-form-box" style={{marginTop:'0px', paddingTop:'5px'}}>
                    <form onSubmit={this.handleSubmit} id="create-game-form">
                      <button className="btn btn-primary btn-lg update-button" id="submitButton" style={{marginBottom:'20px'}}><span className="fa fa-arrow-circle-o-up"/> Update</button>
                      <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                          <li className="nav-item" onClick={()=> this.dynamicToggleControl(1, 'Major', 3)}>
                              <a href="#" aria-expanded="false" className="nav-link active" id="show1Major">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Fan Color/Images</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.dynamicToggleControl(2, 'Major', 3)}>
                              <a href="#" aria-expanded="true" className="nav-link" id="show2Major">
                                  <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Scoreboard Color/Images</span>
                              </a>
                          </li>
                          <li className="nav-item" onClick={()=> this.dynamicToggleControl(3, 'Major', 3)}>
                              <a href="#" aria-expanded="true" className="nav-link" id="show3Major">
                                  <i className="mdi mdi-account-circle d-lg-none d-block mr-1"/>
                                  <span className="d-none d-lg-block">Game Text</span>
                              </a>
                          </li>
                      </ul>
                        <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTab1Major === true ? '' : 'none'}}>
                            <li className="nav-item" onClick={()=> this.dynamicToggleControl(1, "Minor", 3)}>
                                <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="show1Minor">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Colors</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={()=> this.dynamicToggleControl(2, "Minor", 3)}>
                                <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="show2Minor">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Images</span>
                                </a>
                            </li>
                            {process.env.REACT_APP_ALLOW_CAMPAIGN_PICKS === "Yes" && this.state.tenantVariables.allowSeasonLongPicks &&
                                <li className="nav-item" onClick={() => this.dynamicToggleControl(3, "Minor", 3)}>
                                    <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="show3Minor">
                                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                        <span className="d-none d-lg-block">Campaign Images</span>
                                    </a>
                                </li>
                            }
                        </ul>
                        <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTab3Major === true ? '' : 'none'}}>
                            <li className="nav-item" onClick={()=> this.dynamicToggleControl(1, "gameTextToggle", 3)}>
                                <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="show1gameTextToggle">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Branding Text</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={()=> this.dynamicToggleControl(2, "gameTextToggle", 3)}>
                                <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="show2gameTextToggle">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Actions Text</span>
                                </a>
                            </li>
                            {process.env.REACT_APP_ALLOW_CAMPAIGN_PICKS === "Yes" && this.state.tenantVariables.allowSeasonLongPicks &&
                                <li className="nav-item" onClick={() => this.dynamicToggleControl(3, "gameTextToggle", 3)}>
                                    <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="show3gameTextToggle">
                                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                        <span className="d-none d-lg-block">Campaign Messages</span>
                                    </a>
                                </li>
                            }
                        </ul>
                        <ul className="nav nav-tabs nav-justified nav-bordered mb-3" style={{display: this.state.showTab2Major === true ? '' : 'none'}}>
                            <li className="nav-item" onClick={()=> this.dynamicToggleControl(1, "Scoreboard", 3)}>
                                <a href="#" aria-expanded="false" className="nav-link active" id="show1Scoreboard">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Images</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={()=> this.dynamicToggleControl(2, "Scoreboard", 3)}>
                                <a href="#" aria-expanded="true" className="nav-link" id="show2Scoreboard">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Colors</span>
                                </a>
                            </li>
                            <li className="nav-item" onClick={()=> this.dynamicToggleControl(3, "Scoreboard", 3)}>
                                <a href="#" aria-expanded="true" className="nav-link" id="show3Scoreboard">
                                    <i className="mdi mdi-home-variant d-lg-none d-block mr-1"/>
                                    <span className="d-none d-lg-block">Sizes</span>
                                </a>
                            </li>
                        </ul>
                      <div style={{display: this.state.showTab1Major ? 'block' : 'none'}}>
                          <div style={{display: this.state.showTab1Minor ? 'block': 'none'}}>
                              <div className="row">
                                  <div className="col-md-6">
                                      <h4>Button Colors</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("primaryColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.primaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="primaryColor" style={{marginRight: 10}}>Button Color</label>
                                          <input id="primaryColor" name="primaryColor" type="text" className="form-control" value={this.state.tenantVariables.primaryColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("secondaryColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.secondaryColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="secondaryColor" style={{marginRight: 10}}>Button Text Color</label>
                                          <input id="secondaryColor" name="secondaryColor" type="text" className="form-control" value={this.state.tenantVariables.secondaryColor} onChange={this.handleChange} placeholder="#000"/>
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <h4>Logout Color</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("logOutButtonColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.logOutButtonColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="logOutButtonColor" style={{marginRight: 10}}>Button Color</label>
                                          <input id="logOutButtonColor" name="logOutButtonColor" type="text" className="form-control" value={this.state.tenantVariables.logOutButtonColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                      </div>
                                  </div>
                              </div>
                              <div style={{height:'10px', width:'100%'}}/>
                              <div className="row">
                                  <div className="col-md-6">
                                      <h4>Game Colors</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("textColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.textColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="textColor" style={{marginRight: 10}}>Text Color</label>
                                          <input id="textColor" name="textColor" type="text" className="form-control" value={this.state.tenantVariables.textColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                          <div style={{height:'10px', width:'100%'}}/>

                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("highlightPickColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.highlightPickColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="highlightPickColor" style={{marginRight: 10}}>Highlight Pick Color</label>
                                          <input id="highlightPickColor" name="highlightPickColor" type="text" className="form-control" value={this.state.tenantVariables.highlightPickColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                      </div>
                                  </div>
                                  <div className="col-md-6">
                                      <h4>Special Colors</h4>
                                      <div className="form-inline">
                                          <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("myPickTextColor")}/>
                                          <div style={{backgroundColor: this.state.tenantVariables.myPickTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                          <label htmlFor="myPickTextColor" style={{marginRight: 10}}>My Pick Text Color</label>
                                          <input id="myPickTextColor" name="myPickTextColor" type="text" className="form-control" value={this.state.tenantVariables.myPickTextColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>
                                      </div>
                                  </div>
                              </div>
                          </div>
                            <div style={{display: this.state.showTab2Minor ? 'block': 'none'}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="admin-grid-container three-columns" style={{display: "flex",  alignItems: 'flex-end', justifyContent: 'space-evenly'}}>
                                          <div className="form-group" style={{textAlign:'center', margin:20}}>
                                            <img src={this.state.tenantVariables.backgroundImage} width="160" height="auto" alt=""/>
                                            <br/>
                                            <label htmlFor="backgroundImage">Phone Background Image<br/>(750px X 1334px)</label>
                                            <div className="form-group">
                                              <input style={{display:'none'}} id="backgroundImage" name="backgroundImage" type="file" ref={input => {this.backgroundImage = input; }} onChange={this.handleImageChange}/>
                                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('backgroundImage').click()} />
                                            </div>
                                          </div>
                                          <div className="form-group" style={{textAlign:'center', margin:20}}>
                                            <img src={this.state.tenantVariables.frontLogoImage} width="150" height="auto" alt=""/>
                                            <br/>
                                            <label htmlFor="frontLogoImage">Front Logo<br/>(700px X 700px)</label>
                                            <div className="form-group">
                                              <input style={{display:'none'}} id="frontLogoImage" name="frontLogoImage" type="file" ref={input => {this.frontLogoImage = input; }} onChange={this.handleImageChange}/>
                                              <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('frontLogoImage').click()} />
                                            </div>
                                          </div>
                                          <div className="form-group" style={{float:'left', textAlign:'center', margin:20}}>
                                            <img src={this.state.tenantVariables.topLeftImage} width="100" height="auto" alt=""/>
                                            <br/>
                                            <label htmlFor="topLeftImage">Top Logo<br/>(200px X 200px)</label>
                                            <div className="form-group">
                                                <input style={{display:'none'}} id="topLeftImage" name="topLeftImage" type="file" ref={input => {this.topLeftImage = input; }} onChange={this.handleImageChange}/>
                                                <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topLeftImage').click()} />
                                            </div>
                                          </div>
                                        </div>
                                    </div>
                                </div>
                                {isMlbApp &&
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="admin-grid-container three-columns" style={{display: "flex",  alignItems: 'flex-end', justifyContent: 'space-evenly'}}>
                                                <div className="form-group" style={{textAlign:'center', margin:20}}>
                                                    <img src={this.state.tenantVariables.confirmationMessageImage} width="160" height="auto" alt=""/>
                                                    <br/>
                                                    <label htmlFor="confirmationMessageImage">Confirmation Message Image<br/></label>
                                                    <div className="form-group">
                                                        <input style={{display:'none'}} id="confirmationMessageImage" name="confirmationMessageImage" type="file" ref={input => {this.confirmationMessageImage = input; }} onChange={this.handleImageChange}/>
                                                        <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('confirmationMessageImage').click()} />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                            <div style={{display: this.state.showTab3Minor ? 'block': 'none'}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="admin-grid-container three-columns" style={{display: process.env.REACT_APP_ALLOW_CAMPAIGN_PICKS === "Yes" && this.state.tenantVariables.allowSeasonLongPicks ? 'flex' : 'none', alignItems: 'flex-end', justifyContent: 'space-evenly'}}>
                                            <div className="form-group" style={{float:'left', textAlign:'center', margin:20}}>
                                                <img src={this.state.tenantVariables.seasonLongFrontImage} width="100" height="auto" alt=""/>
                                                <br/>
                                                <label htmlFor="seasonLongFrontImage">Season Long Front Logo<br/>(700px X 700px)</label>
                                                <div className="form-group">
                                                    <input style={{display:'none'}} id="seasonLongFrontImage" name="seasonLongFrontImage" type="file" ref={input => {this.seasonLongFrontImage = input; }} onChange={this.handleImageChange}/>
                                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('seasonLongFrontImage').click()} />
                                                </div>
                                            </div>
                                            <div className="form-group" style={{float:'left', textAlign:'center', margin:20}}>
                                                <img src={this.state.tenantVariables.seasonLongTopLogo} width="100" height="auto" alt=""/>
                                                <br/>
                                                <label htmlFor="seasonLongTopLogo">Season Long Top Logo<br/>(200px X 200px)</label>
                                                <div className="form-group">
                                                    <input style={{display:'none'}} id="seasonLongTopLogo" name="seasonLongTopLogo" type="file" ref={input => {this.seasonLongTopLogo = input; }} onChange={this.handleImageChange}/>
                                                    <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('seasonLongTopLogo').click()} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                      </div>
                        <div style={{display: this.state.showTab2Major ? 'block' : 'none'}}>
                            <div style={{display: this.state.showTab1Scoreboard ? 'block': 'none'}}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group" style={{textAlign:'center'}}>
                                            <div style={{cursor:"pointer", display: leftScoreboardBackground?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                                    ...tenantVariables,
                                                    leftScoreboardBackground: null
                                                }})}>
                                                ❌
                                            </div>
                                            <img src={leftScoreboardBackground} width="160" height="auto" alt=""/>
                                            <br/>
                                            <label htmlFor="leftScoreboardBackground">Scoreboard Background Image<br/>(1654px X 926px)</label>
                                            <div className="form-group">
                                                <input style={{display:'none'}} id="leftScoreboardBackground" name="leftScoreboardBackground" type="file" ref={input => {this.leftScoreboardBackground = input; }} onChange={this.handleImageChange}/>
                                                <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('leftScoreboardBackground').click()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group" style={{textAlign:'center'}}>
                                            <div style={{cursor:"pointer", display: leftOfScoreboardImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                                    ...tenantVariables,
                                                    leftOfScoreboardImage: null
                                                }})}>
                                                ❌
                                            </div>
                                            <img src={leftOfScoreboardImage} width="160" height="auto" alt=""/>
                                            <br/>
                                            <label htmlFor="leftOfScoreboardImage">Left Of Scoreboard Image<br/>(1821px X 2988px)</label>
                                            <div className="form-group">
                                                <input style={{display:'none'}} id="leftOfScoreboardImage" name="leftOfScoreboardImage" type="file" ref={input => {this.leftOfScoreboardImage = input; }} onChange={this.handleImageChange}/>
                                                <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('leftOfScoreboardImage').click()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group" style={{textAlign:'center'}}>
                                            <div style={{cursor:"pointer", display: topScoreboardBackground?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                                    ...tenantVariables,
                                                    topScoreboardBackground: null
                                                }})}>
                                                ❌
                                            </div>
                                            <img src={topScoreboardBackground} width="160" height="auto" alt=""/>
                                            <br/>
                                            <label htmlFor="topScoreboardBackground">Vertical Scoreboard Background Image<br/>(1654px X 926px)</label>
                                            <div className="form-group">
                                                <input style={{display:'none'}} id="topScoreboardBackground" name="topScoreboardBackground" type="file" ref={input => {this.topScoreboardBackground = input; }} onChange={this.handleImageChange}/>
                                                <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topScoreboardBackground').click()} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group" style={{textAlign:'center'}}>
                                            <div style={{cursor:"pointer", display: topOfScoreboardImage?"":"none", textAlign:"right"}} onClick={()=>this.setState({tenantVariables: {
                                                    ...tenantVariables,
                                                    topOfScoreboardImage: null
                                                }})}>
                                                ❌
                                            </div>
                                            <img src={topOfScoreboardImage} width="160" height="auto" alt=""/>
                                            <br/>
                                            <label htmlFor="topOfScoreboardImage">Top Of Scoreboard Image<br/>(1821px X 2988px)</label>
                                            <div className="form-group">
                                                <input style={{display:'none'}} id="topOfScoreboardImage" name="topOfScoreboardImage" type="file" ref={input => {this.topOfScoreboardImage = input; }} onChange={this.handleImageChange}/>
                                                <input className='btn btn-primary btn-lg choose-image-button' type="button" value="Choose Image" onClick={() => document.getElementById('topOfScoreboardImage').click()} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: this.state.showTab2Scoreboard ? 'block': 'none'}}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <h4>Scoreboard Colors</h4>
                                        <div className="form-inline">
                                            <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("progressBarBackgroundColor")}/>
                                            <div style={{backgroundColor: this.state.tenantVariables.progressBarBackgroundColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                            <label htmlFor="progressBarBackgroundColor" style={{marginRight: 10}}>Progress Bar Background Color</label>
                                            <input id="progressBarBackgroundColor" name="progressBarBackgroundColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarBackgroundColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                            <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("progressBarColor")}/>
                                            <div style={{backgroundColor: this.state.tenantVariables.progressBarColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                            <label htmlFor="progressBarColor" style={{marginRight: 10}}>Progress Bar Color</label>
                                            <input id="progressBarColor" name="progressBarColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarColor} onChange={this.handleChange} placeholder="#000"/>

                                            <div style={{height:'10px', width:'100%'}}/>

                                            <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("progressBarTextColor")}/>
                                            <div style={{backgroundColor: this.state.tenantVariables.progressBarTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                            <label htmlFor="progressBarTextColor" style={{marginRight: 10}}>Progress Bar Text Color</label>
                                            <input id="progressBarTextColor" name="progressBarTextColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarTextColor} onChange={this.handleChange} placeholder="#000" style={{marginRight:10}}/>

                                            <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("progressBarQuestionTextColor")}/>
                                            <div style={{backgroundColor: this.state.tenantVariables.progressBarQuestionTextColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                            <label htmlFor="progressBarQuestionTextColor" style={{marginRight: 10}}>Progress Bar Question Text Color</label>
                                            <input id="progressBarQuestionTextColor" name="progressBarQuestionTextColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarQuestionTextColor} onChange={this.handleChange} placeholder="#000"/>

                                            <div style={{height:'10px', width:'100%'}}/>

                                            <span style={{marginRight:10}} className="fa fa-eyedropper mobile-hide" onClick={()=> this.openColorPicker("progressBarOutlineScoreboardColor")}/>
                                            <div style={{backgroundColor: this.state.tenantVariables.progressBarOutlineScoreboardColor, marginRight: 10, border: "solid 1px", minHeight: 20, width: 20, display: "inline-block"}}/>
                                            <label htmlFor="progressBarOutlineScoreboardColor" style={{marginRight: 10}}>Progress Bar Outline Color</label>
                                            <input id="progressBarOutlineScoreboardColor" name="progressBarOutlineScoreboardColor" type="text" className="form-control" value={this.state.tenantVariables.progressBarOutlineScoreboardColor} onChange={this.handleChange} placeholder="#000"/>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{display: this.state.showTab3Scoreboard ? 'block' : 'none'}}>
                                <div className="row">
                                    <div className="col-md-6">
                                        <h4>Sizes</h4>
                                        <div className="form-inline">
                                            <label htmlFor="topScoreboardQuestionSize" style={{marginRight: 10, marginLeft: 10}}>Top Scoreboard Question Size</label>
                                            <input id="topScoreboardQuestionSize" name="topScoreboardQuestionSize" type="number" className="form-control" value={this.state.tenantVariables.topScoreboardQuestionSize} onChange={this.handleChange} placeholder="44"/>
                                            <div style={{height:'10px', width:'100%'}}/>
                                        </div>
                                        <div className="form-inline">
                                            <label htmlFor="leftScoreboardQuestionSize" style={{marginRight: 10, marginLeft: 10}}>Left Scoreboard Question Size</label>
                                            <input id="leftScoreboardQuestionSize" name="leftScoreboardQuestionSize" type="number" className="form-control" value={this.state.tenantVariables.leftScoreboardQuestionSize} onChange={this.handleChange} placeholder="44"/>
                                            <div style={{height:'10px', width:'100%'}}/>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                      <div style={{display: this.state.showTab3Major ? 'block' : 'none'}}>
                          <div style={{display: this.state.showTab1gameTextToggle ? 'block': 'none'}}>
                              <div className="form-group">
                                  <label htmlFor="pageTitle">Page Title:</label>
                                  <input id="pageTitle" name="pageTitle" type="text" className="form-control" value={this.state.tenantVariables.pageTitle} onChange={this.handleChange} placeholder={isPrediction?"Prediction":"Race"}/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="aboveSelectionsText">Above Selections Text:</label>
                                  <input id="aboveSelectionsText" name="aboveSelectionsText" type="text" className="form-control" value={this.state.tenantVariables.aboveSelectionsText} onChange={this.handleChange} placeholder="WHO WILL WIN?"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="playingText">Playing Text:</label>
                                  <input id="playingText" name="playingText" type="text" className="form-control" value={this.state.tenantVariables.playingText} onChange={this.handleChange} placeholder="2nd Quarter"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="textAbovePlayingText">Text Above Playing Text:</label>
                                  <input id="textAbovePlayingText" name="textAbovePlayingText" type="text" className="form-control" value={this.state.tenantVariables.textAbovePlayingText} onChange={this.handleChange} placeholder="The Game Will Start In The:"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="selectCharacterGameHeader">Select Character Game Header:</label>
                                  <input id="selectCharacterGameHeader" name="selectCharacterGameHeader" type="text" className="form-control" value={this.state.tenantVariables.selectCharacterGameHeader} onChange={this.handleChange} placeholder="Select This Character?"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="selectCharacterGameBody">Select Character Game Body:</label>
                                  <input id="selectCharacterGameBody" name="selectCharacterGameBody" type="text" className="form-control" value={this.state.tenantVariables.selectCharacterGameBody} onChange={this.handleChange} placeholder="Once you choose you're locked in & can't switch"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="frozenHeader">Frozen Header</label>
                                  <input id="frozenHeader" name="frozenHeader" type="text" className="form-control" value={this.state.tenantVariables.frozenHeader} onChange={this.handleChange} placeholder="Picks Frozen!"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="frozenMessage">Frozen Message</label>
                                  <input id="frozenMessage" name="frozenMessage" type="text" className="form-control" value={this.state.tenantVariables.frozenMessage} onChange={this.handleChange} placeholder="The game has started and no more picks are allowed!"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="afterPickHeader">After Pick Header:</label>
                                  <input id="afterPickHeader" name="afterPickHeader" type="text" className="form-control" value={this.state.tenantVariables.afterPickHeader} onChange={this.handleChange} placeholder="Good Luck!"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="afterPickBody">After Pick Body:</label>
                                  <input id="afterPickBody" name="afterPickBody" type="text" className="form-control" value={this.state.tenantVariables.afterPickBody} onChange={this.handleChange} placeholder="We'll see you at the finish line."/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="myPickText">My Pick Text:</label>
                                  <input id="myPickText" name="myPickText" type="text" className="form-control" value={this.state.tenantVariables.myPickText} onChange={this.handleChange} placeholder="My Pick"/>
                              </div>
                          </div>
                          <div style={{display: this.state.showTab2gameTextToggle ? 'block': 'none'}}>
                              <div className="form-group">
                                  <label htmlFor="winningHeader">Winning Header</label>
                                  <input id="winningHeader" name="winningHeader" type="text" className="form-control" value={this.state.tenantVariables.winningHeader} onChange={this.handleChange} placeholder="You Win!"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="winningMessage">Winning Message</label>
                                  <input id="winningMessage" name="winningMessage" type="text" className="form-control" value={this.state.tenantVariables.winningMessage} onChange={this.handleChange} placeholder="Congratulations"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="winningNoPrizeHeader">Winning Header No Prize</label>
                                  <input id="winningNoPrizeHeader" name="winningNoPrizeHeader" type="text" className="form-control" value={this.state.tenantVariables.winningNoPrizeHeader} onChange={this.handleChange} placeholder="You Win!"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="winningNoPrizeMessage">Winning Message No Prize</label>
                                  <input id="winningNoPrizeMessage" name="winningNoPrizeMessage" type="text" className="form-control" value={this.state.tenantVariables.winningNoPrizeMessage} onChange={this.handleChange} placeholder="Unfortunately all prizes have been given out"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="losingHeaderPrize">Losing Header Prize</label>
                                  <input id="losingHeaderPrize" name="losingHeaderPrize" type="text" className="form-control" value={this.state.tenantVariables.losingHeaderPrize} onChange={this.handleChange} placeholder="You Lose!"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="losingMessagePrize">Losing Message Prize</label>
                                  <input id="losingMessagePrize" name="losingMessagePrize" type="text" className="form-control" value={this.state.tenantVariables.losingMessagePrize} onChange={this.handleChange} placeholder="But you still get a prize!"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="losingHeader">Losing Header No Prize</label>
                                  <input id="losingHeader" name="losingHeader" type="text" className="form-control" value={this.state.tenantVariables.losingHeader} onChange={this.handleChange} placeholder="You Lose!"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="losingMessage">Losing Message No Prize</label>
                                  <input id="losingMessage" name="losingMessage" type="text" className="form-control" value={this.state.tenantVariables.losingMessage} onChange={this.handleChange} placeholder="Better luck next time"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="alreadyHappenedHeader">Event Already Happened Header</label>
                                  <input id="alreadyHappenedHeader" name="alreadyHappenedHeader" type="text" className="form-control" value={this.state.tenantVariables.alreadyHappenedHeader} onChange={this.handleChange} placeholder="Oh no!"/>
                              </div>

                              <div className="form-group">
                                  <label htmlFor="alreadyHappenedMessage">Event Already Happened Message</label>
                                  <input id="alreadyHappenedMessage" name="alreadyHappenedMessage" type="text" className="form-control" value={this.state.tenantVariables.alreadyHappenedMessage} onChange={this.handleChange} placeholder="The contest already happened but come back next time to try again!"/>
                              </div>
                          </div>
                          <div style={{display: this.state.showTab3gameTextToggle ? 'block': 'none'}}>
                              <div className="form-group">
                                  <label htmlFor="campaignLongInfoText">Campaign Long Info Text:</label>
                                  <input id="campaignLongInfoText" name="campaignLongInfoText" type="text" className="form-control" value={this.state.tenantVariables.campaignLongInfoText} onChange={this.handleChange} placeholder="Pick Your Season Long Pick"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="selectCharacterCampaignHeader">Select Character Campaign Header:</label>
                                  <input id="selectCharacterCampaignHeader" name="selectCharacterCampaignHeader" type="text" className="form-control" value={this.state.tenantVariables.selectCharacterCampaignHeader} onChange={this.handleChange} placeholder="Select This Character?"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="selectCharacterCampaignBody">Select Character Campaign Body:</label>
                                  <input id="selectCharacterCampaignBody" name="selectCharacterCampaignBody" type="text" className="form-control" value={this.state.tenantVariables.selectCharacterCampaignBody} onChange={this.handleChange} placeholder="Once you choose you're locked in & can't switch"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="afterPickCampaignHeader">After Pick Campaign Header:</label>
                                  <input id="afterPickCampaignHeader" name="afterPickCampaignHeader" type="text" className="form-control" value={this.state.tenantVariables.afterPickCampaignHeader} onChange={this.handleChange} placeholder="Good Luck!"/>
                              </div>
                              <div className="form-group">
                                  <label htmlFor="afterPickCampaignBody">After Pick Campaign Body:</label>
                                  <input id="afterPickCampaignBody" name="afterPickCampaignBody" type="text" className="form-control" value={this.state.tenantVariables.afterPickCampaignBody} onChange={this.handleChange} placeholder="We'll see you at the finish line."/>
                              </div>
                          </div>
                      </div>
                    </form>
                  </div>
                </div>
            </div>
            <Modal isOpen={this.state.colorPickerShow} toggle={this.state.colorPickerShow} id="myModal">
              <PhotoshopPicker onChangeComplete={ this.handleChangeComplete } color={this.state.colorToShow} onAccept={()=>this.acceptPicker()} onCancel={()=> this.dismissPicker()}/>
            </Modal>
         </div>
        );
    }
}

export default SetUpTeamVariables;
