import React, { Component } from 'react';
import { BrowserRouter, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import Loading from './components/utils/Loading';
import AdminHome from './components/admin/AdminHome';
import AdminLogin from './components/admin/AdminLogin';
import BigScreenVideo from './components/scoreboard/BigScreenVideo';
import BigScreenAnswers from './components/scoreboard/Scoreboard';
import VerticalScoreboard from './components/scoreboard/VerticalScoreboard';
import SetUpTickets from './components/admin/SetUpTickets';
import SetUpRacer from './components/admin/SetUpRacer';
import SetUpGame from './components/admin/SetUpGame';
import SetUpTeamVariables from './components/admin/SetUpTeamVariables';
import SetUpRulesAndRegs from './components/admin/SetUpRulesAndRegs';
import SetUpTicketEmail from './components/admin/SetUpTicketEmail';
import LoginVariables from './components/admin/SetUpLoginVariables';
import SetUpCampaignPicks from './components/admin/SetUpCampaignPicks';
import SetUpApi from './components/admin/SetUpApi';
import ApiDocumentation from './components/admin/ApiDocumentation';
import UserData from './components/admin/UserData';
import DunkinCodeUpload from './components/admin/DunkinCodeUpload';
import { app } from './base';
import isFastNet from 'isfastnet';
import './styles/css/NetworkActivity.css';
import UserInfo from "./components/admin/UserInfo";
import SetUpWebHooks from "./components/admin/SetUpWebHooks";
import WebHooksDocumentation from "./components/admin/WebHooksDocumentation";
import { onAuthStateChanged } from 'firebase/auth';
import { auth } from './base';

function AuthenticatedAdminRoute({ children, authenticated }) {
    let location = useLocation();
    if (!authenticated) {
        return <Navigate to="/adminlogin" state={{ from: location }} />;
    }
    return children;
}

class App extends Component {
    constructor(props) {
        super(props);
        this.setCurrentUser = this.setCurrentUser.bind(this);
        this.state = {
            authenticated: false,
            currentUser: null,
            loading: true,
            speed: null,
        };
    }

    setCurrentUser(user) {
        if (user) {
            this.setState({
                currentUser: user,
                authenticated: true,
            });
        } else {
            this.setState({
                currentUser: null,
                authenticated: false,
            });
        }
    }

    componentDidMount() {
        this.removeAuthListener = onAuthStateChanged(auth, (user) => {
            if (user) {
                this.setState({
                    authenticated: true,
                    currentUser: user,
                    loading: false,
                });
            } else {
                this.setState({
                    authenticated: false,
                    currentUser: null,
                    loading: false,
                });
            }
        });
        isFastNet((value) => {
            let speed = value ? 'fast' : 'slow';
            if (speed !== this.state.speed) {
                this.setState({
                    speed: speed,
                });
                console.log('Internet is ' + speed);
            }
        });
    }

    componentWillUnmount() {
        this.removeAuthListener();
    }

    render() {
        let windowPath = window.location.pathname.toLowerCase();
        if (this.state.loading === true) {
            return <Loading loading={this.state.loading} />;
        }
        return (
            <>
                <BrowserRouter>
                    <Routes>
                        <Route path="/bigscreenvideo" element={<BigScreenVideo />} />
                        <Route path="/bigscreenanswers" element={<BigScreenAnswers />} />
                        <Route path="/verticalscoreboard" element={<VerticalScoreboard />} />
                        <Route
                            path="/adminlogin"
                            element={<AdminLogin setCurrentUser={this.setCurrentUser} user={this.state.currentUser} />}
                        />

                        <Route
                            path="/admin"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <AdminHome />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/setupgame"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <SetUpGame currentUser={this.state.currentUser} />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/longtermpicks"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <SetUpCampaignPicks />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/setupracers"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <SetUpRacer />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/setuptickets"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <SetUpTickets />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/setupteamvariables"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <SetUpTeamVariables />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/setuprulesandregs"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <SetUpRulesAndRegs />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/setupticketemail"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <SetUpTicketEmail />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/setuploginvariables"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <LoginVariables />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/apimanagement"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <SetUpApi />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/apidocumentation"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <ApiDocumentation />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/userdata"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <UserData />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/userinfo"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <UserInfo />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/dunkincodeupload"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <DunkinCodeUpload currentUser={this.state.currentUser} />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/webhooks"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <SetUpWebHooks />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route
                            path="/webhooksdocumentation"
                            element={
                                <AuthenticatedAdminRoute authenticated={this.state.authenticated}>
                                    <WebHooksDocumentation />
                                </AuthenticatedAdminRoute>
                            }
                        />
                        <Route path="*" element={<Navigate to="/adminlogin" replace />} />
                    </Routes>
                </BrowserRouter>
                {this.state.speed === "slow" &&
                    windowPath.indexOf("bigscreen") === -1 && (
                        <div className="slowNetworkAlert fade-in">
                            ⚠️ Slow Network Detected
                            <br />
                            <span style={{ fontSize: 10 }}>Please check your internet</span>
                        </div>
                    )}
            </>
        );
    }
}

export default App;
