import React, { Component } from 'react';
import { getDatabase, ref, onValue, off } from 'firebase/database';
import '../../styles/css/Scoreboard.css';
import AnswerItemsComponent from '../small_components/AnswerItemsComponent';
import { convertIntFontSizeToPx } from '../utils/HelpfulFunction';

class Scoreboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            currentGame: {},
            tenantVariables: {},
            userPicks: []
        };
    }

    componentDidMount() {
        document.title = 'Scoreboard';
        const db = getDatabase();

        const tenantVariablesRef = ref(db, 'tenantVariables');
        this.unsubscribeTenantVariables = onValue(tenantVariablesRef, (snapshot) => {
            this.setState({
                tenantVariables: snapshot.val() || {}
            });
        });

        const currentGameRef = ref(db, 'currentGame');
        this.unsubscribeCurrentGame = onValue(currentGameRef, (snapshot) => {
            this.setState({ currentGame: snapshot.val() || {} });
        });

        const userPicksRef = ref(db, 'userPicks');
        this.unsubscribeUserPicks = onValue(userPicksRef, (snapshot) => {
            const picks = snapshot.val() || [];
            const finalPicks = [];
            let totalCount = 0;
            const objectPicks = {};
            const currentGame = this.state.currentGame || {};

            for (const index in currentGame.racers) {
                const racer = currentGame.racers[index];
                objectPicks[racer.id] = {
                    count: 0,
                    id: racer.id,
                    answerImage: racer.gifSelectionImage,
                    itemName: racer.racerName
                };
            }

            for (const index in picks) {
                const pick = picks[index];
                if (objectPicks[pick.pickid]) {
                    objectPicks[pick.pickid].count += 1;
                    totalCount++;
                }
            }

            for (const countObjectsIndex in objectPicks) {
                finalPicks.push(objectPicks[countObjectsIndex]);
            }

            this.setState({
                userPicks: finalPicks,
                totalCount: totalCount,
                loading: false
            });
        });
    }

    componentWillUnmount() {
        if (this.unsubscribeTenantVariables) off(ref(getDatabase(), 'tenantVariables'), this.unsubscribeTenantVariables);
        if (this.unsubscribeCurrentGame) off(ref(getDatabase(), 'currentGame'), this.unsubscribeCurrentGame);
        if (this.unsubscribeUserPicks) off(ref(getDatabase(), 'userPicks'), this.unsubscribeUserPicks);
    }

    render() {
        const { tenantVariables, userPicks, totalCount, loading } = this.state;
        let questionFontSize = tenantVariables.leftScoreboardQuestionSize || 44;
        questionFontSize = convertIntFontSizeToPx(questionFontSize);
        let show_percentages = true;
        if (tenantVariables.showScoreboardPercentages === 'all') {
            show_percentages = true;
        } else if (tenantVariables.showScoreboardPercentages === 'parttime') {
            show_percentages = true;
        }
        const scoreboardAboveAnswersText = tenantVariables.aboveSelectionsText || 'Results:';
        const backgroundImage = tenantVariables.leftScoreboardBackground;
        const leftOfScoreboardImage = tenantVariables.leftOfScoreboardImage;

        if (loading) {
            return <div className="loading-screen" />;
        }

        return (
            <div
                className="grid-wrapper"
                style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    minHeight: '100vh',
                    backgroundImage: `url(${backgroundImage})`
                }}
            >
                <div className="grid-container-outer">
                    <div className="grid-left">
                        <div className="creator-awards-logo">
                            <img
                                src={leftOfScoreboardImage}
                                alt=""
                                style={{
                                    maxHeight: 'calc(100vh - 50px)',
                                    maxWidth: '100%',
                                    height: 'auto',
                                    width: 'auto'
                                }}
                            />
                        </div>
                    </div>
                    <div style={{ marginRight: '5%' }}>
                        <h1
                            style={{
                                color: tenantVariables.progressBarQuestionTextColor,
                                marginBottom: '30px',
                                fontSize: questionFontSize,
                                fontWeight: 700,
                                textShadow: '2px 2px rgba(0, 0, 0, 0.1)',
                                textAlign: 'left'
                            }}
                        >
                            {scoreboardAboveAnswersText}
                        </h1>
                        <AnswerItemsComponent
                            totalCount={totalCount}
                            answers={userPicks}
                            show_percentages={show_percentages}
                            tenantVariables={tenantVariables}
                            fromScoreboard={true}
                            sideBigScreen={true}
                            onClickAnswer={() => console.log('click')}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Scoreboard;
