import React, { Component } from 'react';
import SideMenu from '../admin/SideMenu';
import TopMenu from '../admin/TopBar';
import {getDatabase, ref, onValue, off, query, orderByChild, equalTo, limitToFirst, set} from 'firebase/database';
import { convertTimeStampToHumanReadable } from '../utils/HelpfulFunction';
import '../../styles/css/AdminMain.css';
import {showSuccessPopup, showErrorPopup, showInfoPopup, showInputPopup, showConfirmPopup} from '../utils/SwalConfig';

class SetUpCampaignPicks extends Component {
    constructor(props) {
        super(props);
        this.state = {
            campaignPicksCount: 0,
            racerList: [],
            tenantVariables: {},
            showStats: true,
            campaignLongPicks: [],
            loading: false,
        };
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        const db = getDatabase();
        this.racerListRef = ref(db, 'racerList');
        this.tenantVariablesRef = ref(db, 'tenantVariables');
        this.campaignPicksRef = ref(db, 'campaignPicks');

        onValue(this.racerListRef, (snapshot) => {
            this.setState({ racerList: snapshot.val() || [] });
        });

        onValue(this.tenantVariablesRef, (snapshot) => {
            this.setState({ tenantVariables: snapshot.val() || {} });
        });

        onValue(this.campaignPicksRef, (snapshot) => {
            this.setState({
                loading: false,
                campaignPicksCount: snapshot.size
            });
        });
    }

    componentWillUnmount() {
        off(this.racerListRef);
        off(this.tenantVariablesRef);
        off(this.campaignPicksRef);
    }

    validUrl(str) {
        const pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }

    getSeasonLongPicks(limitToFirstValue = 25, searchQuery) {
        this.setState({ loading: true });
        const db = getDatabase();
        let queryString = ref(db, 'campaignPicks');
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";

        if (searchQuery) {
            let partialUid = searchQuery;
            if (!isMlbApp) {
                partialUid = btoa(searchQuery);
            }
            queryString = query(queryString, orderByChild('uid'), equalTo(partialUid));
        } else {
            queryString = query(queryString, orderByChild('timeStamp'), limitToFirst(limitToFirstValue));
        }

        onValue(queryString, async (snapshot) => {
            const campaignPicksArray = [];
            snapshot.forEach((data) => {
                campaignPicksArray.push(data.val());
            });

            for (const i in campaignPicksArray) {
                const campaignPick = campaignPicksArray[i];
                let found = false;
                for (const alreadySavedIndex in this.state.campaignLongPicks) {
                    const existingCampaignPick = this.state.campaignLongPicks[alreadySavedIndex];
                    if (existingCampaignPick.uid === campaignPick.uid) {
                        found = existingCampaignPick;
                    }
                }
                if (found) {
                    campaignPicksArray[i]['email'] = found.email || found.uid || "";
                } else {
                    const emailSnapshot = await get(ref(db, `users/${campaignPicksArray[i].uid}/email`));
                    campaignPicksArray[i]['email'] = emailSnapshot.val() || "";
                }
            }

            this.setState({
                campaignLongPicks: campaignPicksArray,
                loading: false
            });
        });
    }

    async resetSeasonLongPicks() {
        const result = await showConfirmPopup('STOP!', 'Are you sure you want to do this? This will remove all the user CAMPAIGN LONG picks!')

        if (result.isConfirmed) {
            const db = getDatabase();
            await set(ref(db, 'campaignPicks'), null);
            this.setState({ campaignLongPicks: [] });
            showSuccessPopup('Picks Reset', 'All campaign long picks have been reset.');
        }
    }

    handleChange(evt) {
        const target = evt.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        this.setState({ [target.name]: value });
    }

    async switchStatsPrizes(buttonClicked) {
        if (buttonClicked === "showStats") {
            document.getElementById('showPrizes').classList.remove('active');
            document.getElementById('showStats').classList.add('active');
            this.setState({
                showStats: true,
                showPrizes: false,
                campaignLongPicks: []
            });
        } else if (buttonClicked === "showPrizes") {
            this.getSeasonLongPicks();
            document.getElementById('showPrizes').classList.add('active');
            document.getElementById('showStats').classList.remove('active');
            this.setState({
                showStats: false,
                showPrizes: true
            });
        }
    }

    render() {
        const tenantVariables = this.state.tenantVariables || {};
        const racerList = this.state.racerList || [];
        const campaignLongPicks = this.state.campaignLongPicks || [];
        const isMlbApp = process.env.REACT_APP_IS_MLB_TEAM === "true";

        return (
            <div className="admin-wrapper">
                <div className="loading-screen" style={{ display: this.state.loading ? 'block' : 'none' }} />
                <SideMenu />
                <TopMenu />
                <div className="admin-main-panel">
                    <div className="container" style={{ padding: '20px', backgroundColor: '#e3eaef' }}>
                        <div className="row">
                            <div className="col-md-3">
                                <a className="btn btn-outline-danger btn-sm" onClick={() => this.resetSeasonLongPicks()} style={{ marginTop: '7px', float: 'right' }}>Reset Picks</a>
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="row justify-content-center">
                            <h3 style={{ color: 'black', margin: '15px' }}>Season Long Picks</h3>
                        </div>
                        <div className="row justify-content-center">
                            <div className="col-md-3" style={{ border: "solid 1px black", margin: 5 }}>
                                <h4 style={{ color: 'black' }}>Racers</h4>
                                {
                                    racerList.map(function (item, index) {
                                        return (
                                            <div key={index} style={{ textAlign: 'left', color: 'black' }}>
                                                <p>Racer: {item.racerName}{item.winner ? ", WINNER" : ""}</p>
                                            </div>
                                        );
                                    })
                                }
                            </div>
                            <div className="col-md-3" style={{ border: "solid 1px black", margin: 5 }}>
                                <h4 style={{ color: 'black' }}>Rewards</h4>
                                <div style={{ textAlign: 'left', color: 'black' }}>
                                    <p>Rewards will be given out when game rewards are distributed if a user has the appropriate racer picked.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className="card-body">
                            <ul className="nav nav-tabs nav-justified nav-bordered mb-3">
                                <li className="nav-item" onClick={() => this.switchStatsPrizes("showStats")}>
                                    <a href="#" data-toggle="tab" aria-expanded="false" className="nav-link active" id="showStats" style={{ backgroundColor: '#fafbfe' }}>
                                        <i className="mdi mdi-home-variant d-lg-none d-block mr-1" />
                                        <span className="d-none d-lg-block">Stats</span>
                                    </a>
                                </li>
                                <li className="nav-item" onClick={() => this.switchStatsPrizes("showPrizes")}>
                                    <a href="#" data-toggle="tab" aria-expanded="true" className="nav-link" id="showPrizes" style={{ backgroundColor: '#fafbfe' }}>
                                        <i className="mdi mdi-account-circle d-lg-none d-block mr-1" />
                                        <span className="d-none d-lg-block">Picks</span>
                                    </a>
                                </li>
                            </ul>
                            <div style={{ display: this.state.showPrizes ? 'block' : 'none' }}>
                                <div className="row" style={{ marginLeft: 10, marginRight: 10 }}>
                                    <p style={{ color: 'black', marginLeft: 20, fontSize: 20, marginTop: 20 }}>Participants</p>
                                    <div className="form-group" style={{ marginLeft: 'auto', marginBottom: 'auto', marginTop: 'auto' }}>
                                        <input
                                            id="searchQuery"
                                            name="searchQuery"
                                            className="form-control"
                                            placeholder={isMlbApp ? "search EXACT uid" : "search EXACT email"}
                                            type="text"
                                            onChange={() => {
                                                const vm = this;
                                                clearTimeout(this.timeoutId); // no-op if invalid id
                                                this.timeoutId = setTimeout(function () {
                                                    let input = document.getElementById('searchQuery').value;
                                                    vm.getSeasonLongPicks(25, input);
                                                }, 500);
                                            }}
                                        />
                                    </div>
                                </div>
                                <table className="table table-striped" style={{ color: 'black' }}>
                                    <tbody>
                                    <tr>
                                        <th>{tenantVariables.doNotCollectEmail || isMlbApp ? "UID" : "Email"}</th>
                                        <th>Pick</th>
                                        <th>Time Stamp</th>
                                    </tr>
                                    {
                                        campaignLongPicks.map((item, i) => {
                                            const timeStamp = convertTimeStampToHumanReadable(item.timeStamp);
                                            let pickName = "";
                                            for (const j in racerList) {
                                                const racer = racerList[j];
                                                if ((racer.id || racer.key) === item.pickid) {
                                                    pickName = racer.racerName;
                                                }
                                            }
                                            return (
                                                <tr key={i}>
                                                    <td style={{ fontFamily: 'Open Sans' }}>{tenantVariables.doNotCollectEmail || isMlbApp ? item.uid : item.email}</td>
                                                    <td style={{ fontFamily: 'Open Sans' }}>{pickName}</td>
                                                    <td style={{ fontFamily: 'Open Sans' }}>{timeStamp}</td>
                                                </tr>
                                            );
                                        })
                                    }
                                    {this.state.campaignPicksCount > campaignLongPicks.length && !document.getElementById('searchQuery').value &&
                                        <button style={{ marginTop: 10 }} className="btn btn-primary" onClick={() => this.getSeasonLongPicks(campaignLongPicks.length + 25)}>
                                            More
                                        </button>
                                    }
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ display: this.state.showStats ? 'block' : 'none' }}>
                                <div className="admin-grid-container three-columns" style={{ marginTop: 20 }}>
                                    <div className="card card-styles text-xs-center">
                                        <div className="card-body">
                                            <blockquote className="card-bodyquote">
                                                <p>Campaign Players</p>
                                                <footer className="value-text">{this.state.campaignPicksCount}</footer>
                                            </blockquote>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default SetUpCampaignPicks;

