import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { signInWithEmailAndPassword, PhoneAuthProvider, PhoneMultiFactorGenerator, RecaptchaVerifier, getMultiFactorResolver, sendEmailVerification, multiFactor } from 'firebase/auth';
import logoImage from '../../styles/images/sqwad-hand.png';
import logoWithTextImageBlack from '../../styles/images/new_sqwad_logo.png';
import '../../styles/css/main.css';
import { auth } from '../../base';
import { showErrorPopup, showInfoPopup, showInputPopup } from '../utils/SwalConfig';

const AdminLogin = (props) => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [loading, setLoading] = useState(false);
    const [code, setCode] = useState("");
    const [step, setStep] = useState("LOGIN");
    const [mfaResolver, setMfaResolver] = useState(null);
    const [verificationId, setVerificationId] = useState(null);
    const [redirect, setRedirect] = useState(false);
    const [enrolling, setEnrolling] = useState(false);
    const [savedPhoneNumber, setPhoneNumber] = useState(null);

    const setupRecaptcha = () => {
        if (typeof window !== 'undefined' && document.getElementById('recaptcha-container') && !window.recaptchaVerifier) {
            window.recaptchaVerifier = new RecaptchaVerifier(auth, 'recaptcha-container', {
                'size': 'invisible',
            });
        } else {
            // Try again after a delay if the container isn't ready yet
            setTimeout(setupRecaptcha, 500);
        }
    };

    useEffect(() => {
        if(props.user){
            setRedirect(true);
        }
        setupRecaptcha();
    }, []);

    const authWithEmailPassword = async (event) => {
        event.preventDefault();
        setLoading(true);
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;

            if (user) {
                if (!user.emailVerified) {
                    const response = await sendEmailVerification(user);
                    setLoading(false);
                    await showInfoPopup('Email Verification Required', 'A verification email has been sent to your email address. Please verify your email before proceeding with multi-factor authentication.');
                } else {
                    await handleMfaOrLogin(user);
                }
            }
        } catch (e) {
            console.log("ERORR DETECTED: ", e)
            if (e.code === "auth/multi-factor-auth-required") {
                await handleMfaSetup(e);
            } else {
                handleAuthError(e);
            }
        }
    };

    const handleMfaOrLogin = async (user) => {
        const mfaInfo = multiFactor(user);
        if (mfaInfo.enrolledFactors.length > 0) {
            // MFA is enabled and there is a phone number enrolled
            await handleMfaSetup({ resolver: getMultiFactorResolver(auth, { user, mfaInfo }) });
        } else {
            setLoading(false);
            // Prompt user to enter a phone number to set up MFA
            const phoneNumber = await showInputPopup('MFA Required', 'We need to set you up for multi-factor authentication, please provide your phone number', '15551234567', validatePhoneNumber);
            if(!phoneNumber) return;
            const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
            if (formattedPhoneNumber) {
                setLoading(true);
                await handlePhoneEnrollment(user, formattedPhoneNumber);
                setLoading(false);
            }
        }
    };

    const handleMfaSetup = async (e) => {
        const resolver = getMultiFactorResolver(auth, e);
        setMfaResolver(resolver);

        const phoneInfoOptions = {
            multiFactorHint: resolver.hints[0],
            session: resolver.session
        };
        const phoneAuthProvider = new PhoneAuthProvider(auth);
        phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier)
            .then((verificationId) => {
                setStep("MFA");
                setVerificationId(verificationId);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                showErrorPopup('Error', error.message);
            });
    };

    const handlePhoneEnrollment = async (user, phoneNumber) => {
        try {
            const phoneAuthProvider = new PhoneAuthProvider(auth);
            const multiFactorSession = await multiFactor(user).getSession();
            const phoneInfoOptions = {
                phoneNumber: phoneNumber,
                session: multiFactorSession
            };
            const gotVerificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, window.recaptchaVerifier);
            setVerificationId(gotVerificationId);
            setStep("MFA");
            setEnrolling(true);
            setPhoneNumber(phoneNumber);
        } catch (error) {
            showErrorPopup('Error', error.message);
            setupRecaptcha(); // Re-initialize the reCAPTCHA verifier
        }
    };

    const validatePhoneNumber = (phoneNumber) => {
        const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
        if (!formattedPhoneNumber) {
            return 'Invalid phone number format. Please enter a valid phone number.';
        }
        return null; // Return null if there's no error
    };

    const formatPhoneNumber = (phoneNumber) => {
        // Remove all non-numeric characters except for the leading '+'
        let normalizedPhoneNumber = phoneNumber.replace(/[^+\d]/g, '');

        // If the phone number does not start with '+'
        if (!normalizedPhoneNumber.startsWith('+')) {
            if (normalizedPhoneNumber.length === 10) {
                // If it's 10 digits, assume it's a U.S. number and prepend '+1'
                normalizedPhoneNumber = `+1${normalizedPhoneNumber}`;
            } else if (normalizedPhoneNumber.length > 10) {
                // If it's more than 10 digits, assume it includes a country code and add the '+'
                normalizedPhoneNumber = `+${normalizedPhoneNumber}`;
            } else {
                // If it's neither, consider it invalid
                return null;
            }
        }

        // Basic validation for E.164 format (starts with + followed by 10 to 15 digits)
        const e164Pattern = /^\+[1-9]\d{10,14}$/;

        // Return the phone number if it matches the E.164 format, otherwise return null
        return e164Pattern.test(normalizedPhoneNumber) ? normalizedPhoneNumber : null;
    };

    const verifyMfaCode = async (event) => {
        event.preventDefault();

        if (!verificationId) return;
        setLoading(true);
        try {
            const credential = PhoneAuthProvider.credential(verificationId, code);
            const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(credential);

            if (enrolling) {
                await multiFactor(auth.currentUser).enroll(multiFactorAssertion, savedPhoneNumber);
            } else {
                await mfaResolver.resolveSignIn(multiFactorAssertion);
            }

            setLoading(false);
            setRedirect(true);
        } catch (error) {
            setLoading(false);

            // Handle specific error for wrong verification code
            if (error.code === 'auth/invalid-verification-code') {
                showErrorPopup('Invalid Code', 'The verification code you entered is incorrect. Please try again.');
            } else {
                showErrorPopup('Error', error.message);
            }
        }
    };

    const handleAuthError = (e) => {
        let bodyMessage = "";
        switch (e.code) {
            case "auth/invalid-credential":
                bodyMessage = "The credential provided is invalid. Please try again.";
                break;
            case "auth/invalid-email":
                bodyMessage = "The email address is not valid. Please enter a valid email address.";
                break;
            case "auth/user-not-found":
                bodyMessage = "The email address is not registered. Please check the email or sign up.";
                break;
            case "auth/wrong-password":
                bodyMessage = "The password is incorrect. Please try again or reset your password.";
                break;
            case "auth/too-many-requests":
                bodyMessage = "Too many requests have been made. Please wait a few minutes before trying again. If the issue persists, please contact support.";
                break;
            case "auth/email-already-in-use":
                bodyMessage = "This email is already associated with an account. Please use a different email or log in.";
                break;
            case "auth/operation-not-allowed":
                bodyMessage = "This operation is not allowed. Please contact support.";
                break;
            case "auth/weak-password":
                bodyMessage = "The password provided is too weak. Please choose a stronger password.";
                break;
            case "auth/requires-recent-login":
                bodyMessage = "This operation requires recent authentication. Please log in again and try.";
                break;
            case "auth/user-disabled":
                bodyMessage = "This user account has been disabled. Please contact support for more information.";
                break;
            case "auth/credential-already-in-use":
                bodyMessage = "This credential is already in use by another account. Please try a different method.";
                break;
            case "auth/missing-email":
                bodyMessage = "An email address is required but was not provided. Please enter your email.";
                break;
            case "auth/account-exists-with-different-credential":
                bodyMessage = "An account already exists with this email address but with a different sign-in method. Please log in using the correct method.";
                break;
            case "auth/invalid-verification-code":
                bodyMessage = "The verification code is invalid. Please check the code and try again.";
                break;
            case "auth/invalid-verification-id":
                bodyMessage = "The verification ID is invalid. Please request a new verification code.";
                break;
            case "auth/network-request-failed":
                bodyMessage = "A network error occurred. Please check your internet connection and try again.";
                break;
            case "auth/missing-phone-number":
                bodyMessage = "A phone number is required but was not provided. Please enter your phone number.";
                break;
            case "auth/quota-exceeded":
                bodyMessage = "The quota for this operation has been exceeded. Please try again later.";
                break;
            case "auth/captcha-check-failed":
                bodyMessage = "The captcha check failed. Please try again.";
                break;
            default:
                bodyMessage = "An unknown error has occurred. Please try again later.";
        }
        setLoading(false);
        showErrorPopup('Oh Uh', bodyMessage);
    };

    if (redirect) {
        return <Navigate to={`/admin`} />
    }

    return (
        <div className="auth-fluid">
            <div id="recaptcha-container"></div>
            <div className="loading-screen" style={{ display: loading ? 'block' : 'none' }} />
            <div className="auth-fluid-form-box">
                <div className="align-items-center d-flex h-100">
                    <div className="card-body">
                        {step === "LOGIN" && (
                            <>
                                <div className="auth-brand text-center text-lg-left" style={{ marginBottom: '50px' }}>
                                    <a href="/">
                                        <img src={logoWithTextImageBlack} alt="" height="auto" width="200px" />
                                    </a>
                                </div>
                                <h4 className="mt-0" style={{ fontWeight: 'bolder' }}>Welcome</h4>
                                <p className="text-muted2 mb-4" style={{ fontWeight: 'bold' }}>Enter your email address and password to access account.</p>
                                <form onSubmit={authWithEmailPassword} style={{ fontWeight: 'bold' }}>
                                    <div className="form-group">
                                        <label htmlFor="emailaddress">Email address</label>
                                        <input className="form-control" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Enter your email" />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="password">Password</label>
                                        <input className="form-control" name="password" type="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Enter your password" />
                                    </div>
                                    <div className="form-group mb-0 text-center" style={{marginTop: 10}}>
                                        <button className="btn btn-primary btn-block" type="submit" style={{ backgroundColor: "#ff1f3e", borderColor: "#ff1f3e", fontWeight: 'bold' }}>Log In</button>
                                    </div>
                                </form>
                            </>
                        )}
                        {step === "MFA" && (
                            <form onSubmit={verifyMfaCode} style={{ marginTop: 10 }}>
                                <div className="form-group">
                                    <label htmlFor="verificationCode">Verification Code</label>
                                    <input className="form-control" type="text" name="verificationCode" value={code} onChange={(e) => setCode(e.target.value)} placeholder="Enter the verification code" />
                                </div>
                                <div className="form-group mb-0 text-center" style={{marginTop: 10}}>
                                    <button className="btn btn-default btn-block" type="button" onClick={() => setStep("LOGIN")}>Back</button>
                                    <button className="btn btn-primary btn-block" type="submit">Verify Code</button>
                                </div>
                            </form>
                        )}
                    </div>
                </div>
            </div>

            <div className="auth-fluid-right text-center mobile-hide">
                <div className="auth-user-testimonial">
                    <h2 className="mb-3" style={{ fontWeight: 'bolder' }}>Race Tip Of The Month</h2>
                    <p className="lead" style={{ fontWeight: 'bold' }}>Plug the phone numbers collected into Facebook, Instagram, & Snapchat ads for ticketing retargeting (5x-8x returns)</p>
                    <img src={logoImage} width="200px" alt="" />
                </div>
            </div>
        </div>
    );
};

export default AdminLogin;
